module.exports = [{
      plugin: require('/Users/bojan/Development/docker-experimenting/gatsby/stellar/beleserv/node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-25981864-1","anonymize":true},"environments":["production"]},
    },{
      plugin: require('/Users/bojan/Development/docker-experimenting/gatsby/stellar/beleserv/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/bojan/Development/docker-experimenting/gatsby/stellar/beleserv/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
